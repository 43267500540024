import { faCheck, faClose } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackClick, TrackingData } from '@nicejob-library/tracking';
import React, { forwardRef, PropsWithChildren, ReactElement } from 'react';
import { CSSProperties, useTheme } from 'styled-components';
import { AppTheme } from '../../theme';
import { Box, IBoxProps } from '../box';
import { DotsShuffling } from '../loading-shapes/dots-shuffling';
import { ButtonRoadBlock } from './atoms/ButtonRoadBlock';
import {
    ButtonBase,
    ButtonContentWrapper,
    ButtonIconWrapper,
    ButtonStateWrapper,
} from './Button.styles';

export const BUTTON_VARIANTS = [
    'action',
    'emphasis',
    'ghost',
    'phantom',
    'subtle',
    'warning',
    'checked',
] as const;
export type IButtonVariants = typeof BUTTON_VARIANTS[number];

export const BUTTON_STATES = ['default', 'hover', 'active', 'disabled', 'loading'] as const;
export type IButtonStates = typeof BUTTON_STATES[number];

export const BUTTON_SIZES = ['small', 'medium', 'large'] as const;
export type IButtonSizes = typeof BUTTON_SIZES[number];

export const BUTTON_ICON_POSITIONS = ['left', 'right'] as const;
export type IButtonIconPositions = typeof BUTTON_ICON_POSITIONS[number];

/**
 * The css properties required to style a button.
 */
interface IButtonCSSProperties {
    color: string;
    background: string;
    border?: string;
}

/**
 * The theme object for all button variants and states.
 */
export type IButtonTheme = {
    [variant in IButtonVariants]: {
        [state in IButtonStates]: IButtonCSSProperties;
    } & {
        loading_dot_colors: [string, string, string, string];
    };
};

/**
 * The props specific to the icon of a button.
 */
export interface IButtonIconProps {
    icon: ReactElement;
    position?: IButtonIconPositions;
    size?: string;
    color?: string;
}

export interface IButtonStyleOverrideProps {
    width?: CSSProperties['width'];
    justifyContent?: CSSProperties['justifyContent'];
}

/**
 * The props for the Button component.
 */
export type IButtonProps = PropsWithChildren<{
    className?: string;
    variant: IButtonVariants;
    size?: IButtonSizes;
    disabled?: boolean;
    loading?: boolean;
    blocked?: boolean;
    id?: string;
    icon?: IButtonIconProps;
    tracking?: TrackingData;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

    // optional props to override the default styles
    sx?: IButtonStyleOverrideProps;

    /** @deprecated Only for backwards compatibility with the MutationButton component. */
    success?: boolean;

    /** @deprecated Only for backwards compatibility with the MutationButton component. */
    error?: boolean;
}>;

/**
 * ## Button
 *
 * The button comes in 4 different variants:
 * - `action`: Used for primary actions.
 * - `emphasis`: Used sparingly for primary actions requiring extra attention.
 * - `ghost`: Used for secondary actions.
 * - `phantom`: Same as `ghost but for use against dark backgrounds.
 * - `subtle`: Alternative style used for secondary or tertiary actions.
 * - `warning`: Used for destructive actions.
 *
 * The button comes in 3 different sizes:
 * - `small`, `medium`, and `large`.
 * - default is `medium` if not specified.
 *
 * The button can be disabled by setting the `disabled` prop to `true`.
 *
 * The button can display a loading animation by setting the `loading` prop to `true`.
 *
 * The button can display a road block crown by setting the `blocked` prop to `true`.
 *
 * The button can display an icon by setting the `icon` prop.
 * - the `icon` prop takes an object with the following properties:
 *   - `icon`: the icon to display. Can be a ReactElement or a ReactNode.
 *   - `position`: the position of the icon. Can be `left` or `right`. Default is `left`.
 *   - `size`: the size of the icon. Can be any valid CSS width value. Default is `20px`.
 *   - `color`: the color of the icon. Can be any valid CSS color value. Default is `currentColor`.
 *
 * The button click can be tracked by setting the `tracking` prop. This calls the
 * `trackClick` function from the `@nicejob-library/tracking` package before
 * propagating the click event to the `onClick` handler prop.
 *
 * The button styles can be overridden with the `sx` prop.
 * - `width` - the width of the button.
 *   - default is `fit-content` if no width is specified.
 *
 * - `justifyContent` - the justify-content value applied to the button content.
 *   - default is `center` if no justifyContent is specified.
 */
export const Button = forwardRef<HTMLButtonElement, IButtonProps>(function Button(props, ref) {
    const { children, onClick, tracking, ...props_without_children } = props;

    function onClickHandler(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        // don't allow clicking when the button is disabled, loading, success, or error
        if (!props.disabled && !props.loading && !props.success && !props.error) {
            // track the click if tracking data is provided
            if (tracking) {
                trackClick(tracking);
            }

            onClick(event);
        }
    }

    return (
        <ButtonBase {...props_without_children} onClick={onClickHandler} ref={ref}>
            <ButtonContent {...props}>{children}</ButtonContent>
        </ButtonBase>
    );
});

/**
 * Wrapper for the button content.
 * - responsible for displaying the button text, icon, and loading animation.
 */
function ButtonContent(props: IButtonProps): ReactElement {
    const { blocked, children, icon, loading, variant, sx, success, error } = props;
    const { button: button_theme } = useTheme() as AppTheme;

    return (
        <>
            <ButtonStateWrapper>
                {loading && (
                    <DotsShuffling
                        size={8}
                        shrink={true}
                        colors={button_theme[variant].loading_dot_colors}
                    />
                )}

                {success && <FontAwesomeIcon icon={faCheck} />}

                {error && <FontAwesomeIcon icon={faClose} />}
            </ButtonStateWrapper>

            <ButtonContentWrapper
                loading={loading}
                success={success}
                error={error}
                icon={icon}
                sx={sx}
            >
                {icon && <ButtonIconWrapper {...props.icon}>{icon.icon}</ButtonIconWrapper>}
                {children}
            </ButtonContentWrapper>

            {blocked && <ButtonRoadBlock />}
        </>
    );
}

export type IButtonGroupProps = PropsWithChildren<{
    position?: 'left' | 'right' | 'center';
    sx?: IBoxProps['sx'];
}>;

/**
 * Container for grouping buttons together in a row.
 *
 * This is a convenience component that provides a flexbox container
 * using the `Box` component. It provides some default styling for
 * grouping buttons together in a row and can be extended using the
 * `sx` prop.
 *
 * @param props.position - The position of the buttons in the group. Default is `left`.
 * @param props.children - The buttons to display in the group.
 * @param props.sx - Optional additional layout/position styling.
 */
export function ButtonGroup(props: IButtonGroupProps): ReactElement {
    const { children, position, sx } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent:
                    position === 'center'
                        ? 'center'
                        : position === 'right'
                        ? 'flex-end'
                        : 'flex-start',
                gap: '8px',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
}
