export enum Paths {
    // frontend
    BASE = '/',
    CAMPAIGNS_V2_BASE = '/campaigns-v2',
    CAMPAIGNS_V2_REVIEW_JOURNEY = '/campaigns-v2?review_journey=true',
    CONVERT_BASE = '/convert',
    SETTINGS_REVIEW_SOURCES = '/settings/product/channels',
    SETTINGS_COMPANY_PROFILE = '/settings/company/profile',
    SETTINGS_COMPANY_TEAM = '/settings/company/team',
    SETTINGS_ACCOUNT_TEAM = '/settings/account/team',
    SETTINGS_ACCOUNT_PLAN = 'settings/account/plans',
    SETUP_STEP_COMPANY_PROFILE = '/setup/get-started/company-profile',
    SETUP_STEP_CHANNELS = '/setup/get-started/channels',
    STORIES_BASE = '/stories',
    PAYMENTS_BASE = '/payments',
    ALL_COMPANIES_INSIGHTS = '/all/insights',
    ALL_COMPANIES_STORIES = '/all/stories',
    ATOM_ACTIVATION = '/atom-activation',

    // frontend - payments
    PAYMENTS = '/payments',
    TRANSACTIONS = '/payments/transactions',
    SETTLEMENTS = '/payments/settlements',
    DISPUTES = '/payments/disputes',
    LOYALTY = '/payments/loyalty',

    // login
    LOGIN_BASE = '/login',
    LOGIN_ALL = '/u/login',

    // onboarding
    ONBOARDING_BASE = '/u/onboarding',
    ONBOARDING_ALL = '/u/onboarding*',
    ONBOARDING_LOGIN_AND_REDIRECT = '/u/onboarding/login-and-redirect',
    ONBOARDING_PARTNER = '/u/onboarding/partner',
    REGISTER_BASE = '/u/register',
    ATOM_REGISTER_BASE = '/u/register/atom',

    // phone-verification
    PHONE_VERIFICATION_ALL = '/u/phone-verification*',
    PHONE_VERIFICATION_SEND_PHONE_CODE = '/u/phone-verification/send-phone-code',
    PHONE_VERIFICATION_VERIFY_PHONE_CODE = '/u/phone-verification/verify-phone-code',

    // features
    FEATURES_ALL = '/u/features*',
    FEATURES_CREATE_CONTEXT = '/u/features/create-context',

    // outbound oauth route
    OAUTH_REQUEST_COMPANY = '/auth/:company_id/request/:network',
    OAUTH_REQUEST_ONBOARDING = '/auth/request/:network',
    OAUTH_CALLBACK = '/auth/handle/:network',
    OAUTH_CALLBACK_COMPANY = '/auth/handle/:network/company/:company_id',
    OAUTH_PAGE_DISPLAY_COMPANY = '/auth/:company_id/pages/:network',
    OAUTH_PAGE_DISPLAY_ONBOARDING = '/auth/pages/:network',
    OAUTH_PAGE_SELECTION_COMPANY = '/auth/:company_id/pages/:network/:page_id',
    OAUTH_PAGE_SELECTION_ONBOARDING = '/auth/pages/:network/:page_id',

    RESET_PASSWORD = '/u/reset_password',
    FORGOT_PASSWORD = '/u/forgot_password',
    SET_PASSWORD = '/u/set_password',
}
