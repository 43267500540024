import React, { forwardRef, ReactElement, RefObject, useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import {
    StyledIconWrapper,
    StyledInputField,
    StyledInputPortion,
    StyledLoading,
} from './InputField.styles';

const Checkmark = require('./assets/checkmark.svg');

export interface InputFieldProps {
    ref?: RefObject<HTMLInputElement>;
    label?: string;
    variant: InputVariantType;
    type?: InputType;
    name?: string;
    required?: boolean;
    disabled?: boolean;
    loading?: boolean;
    invalid?: boolean;
    placeholder?: string;
    valid?: boolean;
    icon?: InputIconProps;
    test_id?: string;
    initial_value?: string;
    autoFocus?: boolean;
    max_length?: number;
    hide_background?: boolean;
    validator?: (value: string) => boolean;
    formatter?: (value: string) => string;
    onChange?: (value: string) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onMouseUp?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export interface IStyledInputPortionProps extends Omit<InputFieldProps, 'onChange' | 'variant'> {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export type InputVariantType = 'x-small' | 'small' | 'medium' | 'large' | 'stretch';
export type InputType = 'number' | 'text' | 'email' | 'password' | 'tel';

export type InputIconProps = {
    value: IconType;
    position_right?: boolean;
    color?: string;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(function(
    props,
    ref
): ReactElement {
    const {
        label,
        icon,
        valid,
        placeholder,
        loading,
        variant,
        type,
        name,
        required,
        disabled,
        invalid,
        initial_value,
        autoFocus,
        validator,
        formatter,
        onChange,
        onFocus,
        onBlur,
        onKeyPress,
        onKeyUp,
        onMouseUp,
        max_length,
        test_id,
    } = props;
    const [input_value, setInputValue] = useState(initial_value || '');

    useEffect(() => {
        if (['number', 'string'].includes(typeof initial_value)) {
            setInputValue(initial_value || '');
        }
    }, [initial_value]);

    // Handle Input change
    useEffect(() => {
        onChange && onChange(input_value);
    }, [input_value]);

    return (
        <StyledInputField variant={variant} disabled={disabled} valid={valid} invalid={invalid}>
            {loading ? (
                <StyledLoading src={'https://cdn.nicejob.co/assets/spinner.gif'} />
            ) : (
                <>
                    {icon && !icon.position_right && (
                        <StyledIconWrapper>
                            {icon.value({
                                size: '14px',
                                color: icon.color,
                            })}
                        </StyledIconWrapper>
                    )}
                    <StyledInputPortion
                        ref={ref}
                        autoFocus={autoFocus}
                        placeholder={placeholder}
                        required={required}
                        data-testid={test_id}
                        value={input_value}
                        maxLength={max_length}
                        type={type || 'text'}
                        name={name}
                        aria-label={label || name}
                        disabled={disabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                            const new_value = formatter
                                ? formatter(e.target.value)
                                : e.target.value;

                            if (!validator) {
                                setInputValue(new_value);
                            }
                            if (validator && validator(new_value)) {
                                setInputValue(new_value);
                            }
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyPress}
                        onKeyUp={onKeyUp}
                        onMouseUp={onMouseUp}
                    />
                    {icon && icon.position_right && (
                        <StyledIconWrapper>
                            {icon.value({
                                size: '14px',
                                color: icon.color,
                            })}
                        </StyledIconWrapper>
                    )}
                </>
            )}
        </StyledInputField>
    );
});
