export enum CompanyProductPlan {
    FREE = 'FREE',
    LITE = 'LITE',
    WIDGETS = 'WIDGETS',
    STANDARD = 'STANDARD',
    PRO = 'PRO',
    PARTNER = 'PARTNER',
}

export enum CompanyProductPlanDate {
    Free = 'Free',
    Legacy = 'Legacy',
    '2019-01' = '2019-01',
    '2019-09' = '2019-09',
    '2020-11' = '2020-11',
    '2023-08' = '2023-08',
    '2024-01' = '2024-01',
    '2024-03' = '2024-03',
    '2025-01' = '2025-01',
}

export const PAID_COMPANY_PRODUCT_PLANS = [
    CompanyProductPlan.LITE,
    CompanyProductPlan.STANDARD,
    CompanyProductPlan.PRO,
];
